import React, { Component } from "react";
import ModalVideo from 'react-modal-video';
import { FiChevronUp } from "react-icons/fi";
import { FaAngleRight, FaAngleLeft } from "react-icons/fa";
import ScrollToTop from 'react-scroll-up';
//components
import PageHelmet from "../component/common/Helmet";
import {t} from "../locales/i18n"
import Footer from "../component/footer/Footer";
//elements
import Breadcrumb from "../elements/common/Breadcrumb";
import {post} from "../networking/Server";
import {goPage} from "../function/goPage";


class VideoPopup extends Component {
    constructor() {
        super()
        this.state = {
            isOpen: false,
            notifys: []
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal() {
        this.setState({ isOpen: true })
    }
    componentDidMount () {
        this.firstfunction()
    }
    firstfunction = async () =>{
        try{
            const notify = await post('youtuber/viewnotifywebtwo');
            this.setState({
                notifys:notify.announcements,
            })
        }catch (e) {
            console.log("Veritabanı Hatası(2): " + e)
        }
    }
    render() {
        const { notifys } = this.state;
        console.warn(notifys)
        notifys.reverse()
        return (
            <>
                <PageHelmet pageTitle='Youtuber | Announcements' />
                <Breadcrumb title={t("my_announcements")} />
                <main className="page-wrapper">
                    <div className="rn-section ptb--120 bg_color--1">
                        <div className="container">
                            <div className="row sercice-details-content">
                                {
                                    notifys?notifys.map((notify,index)=>(
                                        <div className="col-lg-12 mb-md-5" index={index}>
                                            <div className="portfolio">
                                                <div className="thumbnail-inner">
                                                    <div className={`thumbnail`} style={{backgroundImage: "url(" + notify.image + ")"}}/>
                                                    <div className={`bg-blr-image`}/>
                                                </div>
                                                <div className="content">
                                                    <div className="inner">
                                                        <h4><a>{notify.title}</a></h4>
                                                        <div className="portfolio-button"
                                                             onClick={()=>{
                                                                 goPage(this,"/announcements/detail",{announcements_id:notify.announcements_id},false)
                                                             }}
                                                        >
                                                            <a className="rn-btn">{t("announcements_detail")}</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )):null
                                }
                            </div>
                        </div>
                    </div>
                </main>
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                <Footer />
            </>
        )
    }
}

export default VideoPopup;
