import React, { Component } from 'react';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import {FaTwitter ,FaInstagram ,FaFacebookF , FaYoutube} from "react-icons/fa";
import Helmet from "../component/common/Helmet";
import Header from "../component/header/Header";
import ContactThree from "../elements/contact/ContactThree";
import {post} from "../networking/Server";
import {goPage} from "../function/goPage";
import {t} from "../locales/i18n"

const title = t("my_about");
class Home extends Component {
    constructor() {
        super()
        this.state = {
            isOpen: false,
            videos: [],
            notifys: [],
            blogs:[],
            products:[],
            abouts:[],
            SocialShare : [
                {Social: <FaFacebookF /> , link: ''},
                {Social: <FaYoutube /> , link: ''},
                {Social: <FaInstagram /> , link: ''},
                {Social: <FaTwitter /> , link: ''},
            ]
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal() {
        this.setState({ isOpen: true })
    }
    componentDidMount () {
        this.firstfunction()
    }
    hasdiscount(price,discount){
        return price - (price * (discount / 100));
    }
    firstfunction = async () =>{
        try{
            const about = await post('youtuber/getyoutuber');
            const video = await post('youtuber/videoviewweb');
            const notify = await post('youtuber/viewnotifyweb');
            const blog = await post('youtuber/web');
            const products = await post('youtuber/productsviewtwo');
            this.setState({
                videos:video.video,
                notifys:notify.announcements,
                blogs:blog.blog,
                products:products.productthree,
                abouts:about.youtuber,
                SocialShare : [
                    {Social: <FaFacebookF /> , link: about.youtuber[0].facebook},
                    {Social: <FaYoutube /> , link: about.youtuber[0].youtube},
                    {Social: <FaInstagram /> , link: about.youtuber[0].instagram},
                    {Social: <FaTwitter /> , link: about.youtuber[0].twitter},
                ]
            })
        }catch (e) {
            console.log("Veritabanı Hatası (web): " + e)
        }
    }
    render() {
        const {videos , notifys , blogs , products , abouts,SocialShare} = this.state;
        return (
            <div className="active-dark">
                {/* Title */}
                <Helmet pageTitle="Youtuber" />
                {/* Header */}
                <Header homeLink="/" logo="symbol-dark" color="color-black" />
                {/* Start Home Area   */}
                <div id="home" className="fix">
                    <div className="slider-wrapper">
                        {/* Start Single Slide */}
                        {
                            abouts[0]?
                                abouts.map((value, index) => (
                                    <div className="slide personal-portfolio-slider slider-paralax slider-style-3 d-flex align-items-center justify-content-center bg_image"
                                         style={{backgroundImage: "url(" + abouts[0].backgroundtheme + ")"}}
                                         key={index}>
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className={`inner text-left`}>
                                                        <h2 className="title">{abouts[0].title}<br />
                                                            {abouts[0].explain}
                                                        </h2>
                                                        {
                                                            //{abouts[0].explain ? <p className="description">{abouts[0].explain}</p> : ''}
                                                        }
                                                        <div className="slide-btn"><a className="rn-button-style--2 btn-primary-color" href={abouts[0].youtube}>{t('youtube_page')}</a></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                                :null
                        }
                        {/* End Single Slide */}
                    </div>
                </div>
                {/* End Home Area   */}

                {/* Start Video Area  */}
                <div id="video" className="fix">
                    <div className="rn-section ptb--120 bg_color--1">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                    <div className="section-title text-left">
                                        <h2>{t('videos')}</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="row sercice-details-content ">
                                {
                                    videos?videos.map((video, index) => (
                                        <div className="col-lg-4 mt--40" index={index}>
                                            <a href={'https:///www.youtube.com/watch?v='+video.link}>
                                                <div className="thumb position-relative video-thumb">
                                                    <div className="w-100"
                                                         style={{
                                                             backgroundImage: "url(" + video.videoimage + ")"
                                                         }}
                                                         alt="Service Images"/>
                                                    <button
                                                        className="video-popup position-top-center theme-color md-size"
                                                        onClick={this.openModal}><span className="play-icon"/>
                                                    </button>
                                                </div>
                                                <div className="content mt--10">
                                                    <h4 className="title">
                                                        <span>{video.subject}</span>
                                                        <p className="description c-1 f-1">{video.explanation}</p>
                                                    </h4>
                                                </div>
                                            </a>
                                        </div>
                                    )):null
                                }
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="view-more-btn mt--60 mt_sm--30 text-center">
                                        <a className="rn-button-style--2 btn-solid" href="/videos"><span>{t('more')}</span></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Video Area  */}
                {/* Start Announcement Area */}
                <div id="announcement" className="fix">
                    <div className="portfolio-area ptb--120 bg_color--5">
                        <div className="portfolio-sacousel-inner mb--55">
                            <React.Fragment>
                                <div className="portfolio-wrapper">
                                    <div className="container">
                                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                            <div className="section-title text-left">
                                                <h2>{t('my_announcements')}</h2>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="portfolio-slick-activation mt--70 mt_sm--40">
                                        {
                                            notifys?notifys.map((notifys, index) => (
                                                <div className="portfolio" key={index}>
                                                    <div className="thumbnail-inner">
                                                        {
                                                            notifys.image?
                                                                <div className={`thumbnail`}
                                                                     style={{
                                                                         backgroundImage: "url(" + notifys.image + ")"
                                                                     }}
                                                                />
                                                                :
                                                                <div className={`thumbnail`}
                                                                     style={{
                                                                         backgroundImage: "url(" + `${abouts[0].defaultannouncement}`+ ")"
                                                                     }}
                                                                />
                                                        }
                                                    </div>
                                                    <div className="content">
                                                        <div className="inner">
                                                            <h4><a>{notifys.title}</a></h4>
                                                            <div
                                                                className="portfolio-button"
                                                                onClick={()=>{
                                                                    goPage(this,"/announcements/detail",{announcements_id:notifys.announcements_id},false)
                                                                }}
                                                            >
                                                                <a className="rn-btn">{t("products_detail")}</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )):null
                                        }
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="view-more-btn mt--50 mb--100 mt_sm--30 text-center">
                                                <a className="rn-button-style--2 btn-solid" href="/announcements"><span>{t('more')}</span></a>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </React.Fragment>
                        </div>
                    </div>
                </div>
                {/* End Announcement Area */}
                {/* Start Blog Area */}
                <div id="blog" className="fix">
                    <div className="rn-blog-area pt--120 bg_color--1 mb-dec--30">
                        <div className="container">
                            <div className="row align-items-end">
                                <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                    <div className="section-title text-left">
                                        <h2>{t("my_blogs")}</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt--60 mt_sm--40">
                                {
                                    blogs?abouts[0]?blogs.map((blog, i) => (
                                        <div className="col-lg-4 col-md-6 col-12" key={i}>
                                            <div className="blog blog-style--1 ">
                                                <a>
                                                    <div className="thumbnail blog-thumb">
                                                        {
                                                            blog.imageblog?
                                                                <div className="w-100"
                                                                     style={{
                                                                         backgroundImage: "url(" + blog.imageblog + ")"
                                                                     }}
                                                                     alt="Blog Images"
                                                                />
                                                                :
                                                                <div className="w-100"
                                                                    //require('../images/blog.jpg')
                                                                     style={{
                                                                         backgroundImage: "url(" + `${abouts[0].defaultblog}`+ ")"
                                                                     }}
                                                                     alt="Blog Images"
                                                                />
                                                        }
                                                    </div>
                                                </a>
                                                <div className="content">
                                                    <h4 className="title"><a>{blog.title}</a></h4>
                                                    <div className="blog-btn">
                                                        <a className="rn-btn text-white" onClick={()=>{
                                                            goPage(this,"/blogs/detail",{blog_id:blog.blog_id},false)
                                                        }}>{t("blogs_detail")}</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )):null:null
                                }
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="view-more-btn mt--50 mb--100 mt_sm--30 text-center">
                                        <a className="rn-button-style--2 btn-solid" href="/blogs"><span>{t("more")}</span></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Blog Area */}
                {/* Start Product Area */}
                <div id="products" className="fix">
                    <div className="rn-section ptb--120 bg_color--5">
                        <div className="container">
                            <div className="row align-items-end">
                                <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                    <div className="section-title text-left">
                                        <h2>{t("my_products")}</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt--60 mt_sm--40">
                                {
                                    products?products.map((products, i) => (
                                        <div className="col-lg-4 col-md-6 col-12" key={i}>
                                            <div className="service ">
                                                <div className="thumbnail blog-thumb">
                                                    <div className="w-100 h-75"
                                                         style={{
                                                             backgroundImage: "url(" + products.imageproducts + ")"
                                                         }}
                                                    />
                                                    <div className="mt-3">
                                                        <div style={{display:'flex'}}>
                                                            <div>
                                                                <h4 className="title"><a >{products.titleproducts.substring(0,5)}...</a></h4>
                                                                <div className="blog-btn">
                                                                    <a className="rn-btn text-white" onClick={()=>{
                                                                        goPage(this,"/products/detail",{products_id:products.products_id},false)
                                                                    }}>{t("products_detail")}</a>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                {
                                                                    products.discount?
                                                                        <div style={{ display: "flex" }}>
                                                                            <h2 className="mb--0" >{this.hasdiscount(products.price,products.discount)}TL</h2>
                                                                            <h3 className="mb--0" style={{ display: "flex", flexDirection: "column", margin:8  }}>
                                                                                <span className="100TL" style={{ color: "#f81f01" }}>{products.discount}%</span>
                                                                                <span style={{ textDecoration: "line-through", color: "#777777" }}>{products.price}TL</span>
                                                                            </h3>
                                                                        </div>
                                                                        :
                                                                        <div style={{  display: "flex" }}>
                                                                            <h1 className="mb--0" >{products.price}TL</h1>
                                                                        </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )):null
                                }
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="view-more-btn mt--50 mb--100 mt_sm--30 text-center">
                                        <a className="rn-button-style--2 btn-solid" href="/products"><span>{t("more")}</span></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Products Area */}
                <div style={{backgroundColor:'#ededed',height:5}}/>
                {/* Start About Area */}
                <div id="about" className="fix">
                    <div className="about-area ptb--120  bg_color--5">
                        <div className="about-wrapper">
                            <div className="container">
                                <div className="row row--35 align-items-center">
                                    <div className="col-lg-5">
                                        {
                                            abouts[0]?
                                                <div className="thumbnail">
                                                    <img className="w-100" src={`${abouts[0].youtuberimage}`} alt="About Images" />
                                                </div>:null
                                        }
                                    </div>
                                    <div className="col-lg-7">
                                        <div className="about-inner inner">
                                            <div className="section-title">
                                                <h2 className="title">{title}</h2>
                                                {
                                                    abouts[0]?
                                                        <p className="description">{abouts[0].myabout}</p>
                                                        :null
                                                }
                                            </div>
                                            {
                                                abouts[0]?
                                                    <div className="row mt--30">
                                                        <div>
                                                            {/* Start Tabs Area */}
                                                            <div className="tabs-area">
                                                                <div className="container">
                                                                    <div className="row">
                                                                        <div className="col-lg-12">
                                                                            <Tabs>
                                                                                <TabList  className="tab-style--1">
                                                                                    <Tab>{t("personel_information")}</Tab>
                                                                                    <Tab>{t("social_media")}</Tab>
                                                                                    <Tab>{t("education_expert")}</Tab>
                                                                                    <Tab>{t("with_work")}</Tab>
                                                                                </TabList>

                                                                                <TabPanel>
                                                                                    <div className="single-tab-content">
                                                                                        <ul>
                                                                                            <li>
                                                                                                {abouts[0].personalinformation}
                                                                                            </li>
                                                                                        </ul>
                                                                                    </div>
                                                                                </TabPanel>

                                                                                <TabPanel>
                                                                                    <div className="single-tab-content">
                                                                                        <ul>
                                                                                            <li>
                                                                                                {abouts[0].email}
                                                                                            </li>
                                                                                            <li>
                                                                                                {abouts[0].facebook}
                                                                                            </li>
                                                                                            <li>
                                                                                                {abouts[0].twitter}
                                                                                            </li>
                                                                                            <li>
                                                                                                {abouts[0].youtube}
                                                                                            </li>
                                                                                        </ul>
                                                                                    </div>
                                                                                </TabPanel>

                                                                                <TabPanel>
                                                                                    <div className="single-tab-content">
                                                                                        <ul>
                                                                                            <li>
                                                                                                {abouts[0].educationexpertise}
                                                                                            </li>
                                                                                        </ul>
                                                                                    </div>
                                                                                </TabPanel>

                                                                                <TabPanel>
                                                                                    <div className="single-tab-content">
                                                                                        <ul>
                                                                                            <li>
                                                                                                {abouts[0].withwork}
                                                                                            </li>
                                                                                        </ul>
                                                                                    </div>
                                                                                </TabPanel>

                                                                            </Tabs>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* End Tabs Area */}
                                                        </div>
                                                    </div>:null
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area */}
                {/* Start COntact Area */}
                <div id="contact" className="fix">
                    <div className="rn-contact-area ptb--120 bg_color--1">
                        {
                            abouts[0]?
                                <ContactThree contactImages={`${abouts[0].youtuberimagetwo}`} contactTitle="Bana Ulaşın!" />
                                :
                                null
                        }
                    </div>
                </div>
                {/* End COntact Area */}
                <div className="footer-style-2 ptb--30 bg_image bg_image--1" data-black-overlay="6">
                    <div className="wrapper plr--50 plr_sm--20">
                        <div className="row align-items-center justify-content-between">
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                <div className="inner">
                                    <div className="logo text-center text-sm-left mb_sm--20">
                                        <a>
                                            {
                                                abouts[0]?<img style={{borderRadius:100,width:50,height:50}} src={`${abouts[0].iconimage}`} alt="Logo images"/>:null
                                            }
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                <div className="inner text-center">
                                    <ul className="social-share rn-lg-size d-flex justify-content-center liststyle">
                                        {
                                            SocialShare.map((val , i) => (
                                                <li key={i}><a href={`${val.link}`}>{val.Social}</a></li>
                                            ))
                                        }
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                                <div className="inner text-lg-right text-center mt_md--20 mt_sm--20">
                                    <div className="text">
                                        <p>Copyright © 2020 CyberIstanbul.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
            </div>
        )
    }
}


export default Home;
