export function goPage(_this,path,object={},new_page=false) {
    let _object="";

    Object.entries(object).map((value,index)=>{
        if(index===0)
            _object+=value[0]+"="+value[1];
        else
            _object+=","+value[0]+":"+value[1];
    });

    if(new_page){
        const url = "localhost:3000"+path+"?"+_object;
        window.open(url);
    }else{
        _this.props.history.push({
            pathname: path,
            search: '?'+_object
        })
    }

}
