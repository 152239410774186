import i18n from 'i18n-js';
import detectBrowserLanguage from 'detect-browser-language';
import tr from './tr.json'
import en from './en.json'

i18n.translations= {
    tr,
    en
}
//localStorage.getItem("lang") ||
i18n.locale = detectBrowserLanguage().split('-')[0]
i18n.fallbacks = true

export const t = (name, params = {}) => i18n.t(name, params)

export default i18n
