import React, { Component } from "react";
import { FaTwitter, FaInstagram, FaFacebookF, FaYoutube } from "react-icons/fa";
import { FiX, FiMenu } from "react-icons/fi";
import Scrollspy from 'react-scrollspy'
import {post} from "../../networking/Server";
import {t} from "../../locales/i18n"
import Button from "react-bootstrap/Button";
import {goPage} from "../../function/goPage";

class HeaderThree extends Component {
    constructor(props) {
        super(props);
        this.menuTrigger = this.menuTrigger.bind(this);
        this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
        this.stickyHeader = this.stickyHeader.bind(this);

        //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
        window.addEventListener('load', function () {
            console.log('All assets are loaded');
        })
        this.state={
            abouts:[],
            SocialShare : [
                {Social: <FaFacebookF /> , link: ''},
                {Social: <FaYoutube /> , link: ''},
                {Social: <FaInstagram /> , link: ''},
                {Social: <FaTwitter /> , link: ''},
            ]
        }
    }
    componentDidMount () {
        this.firstfunction()
    }
    firstfunction = async () =>{
        try{
            const about = await post('youtuber/getyoutuber');
            this.setState({
                abouts:about.youtuber,
                SocialShare : [
                    {Social: <FaFacebookF /> , link: about.youtuber[0].facebook},
                    {Social: <FaYoutube /> , link: about.youtuber[0].youtube},
                    {Social: <FaInstagram /> , link: about.youtuber[0].instagram},
                    {Social: <FaTwitter /> , link: about.youtuber[0].twitter},
                ]
            })
        }catch (e) {
            console.log("Veritabanı Hatası(2): " + e)
        }
    }
    menuTrigger() {
        document.querySelector('.header-wrapper').classList.toggle('menu-open')
    }

    CLoseMenuTrigger() {
        document.querySelector('.header-wrapper').classList.remove('menu-open')
    }

    stickyHeader() { }

    render() {
        const {abouts,SocialShare} = this.state;

        /*
        window.addEventListener('scroll', function () {
            var value = window.scrollY;
            if (value > 100) {
                document.querySelector('.header--fixed').classList.add('sticky')
            } else {
                document.querySelector('.header--fixed').classList.remove('sticky')
            }
        });
        */

        var elements = document.querySelectorAll('.has-droupdown > a');
        for (var i in elements) {
            if (elements.hasOwnProperty(i)) {
                elements[i].onclick = function () {
                    this.parentElement.querySelector('.submenu').classList.toggle("active");
                    this.classList.toggle("open");
                }
            }
        }
        const { logo, color = 'default-color' } = this.props;
        let logoUrl;
        if(abouts[0]) logoUrl = <img style={{borderRadius:100,width:50,height:50}} src={`${abouts[0].iconimage}`} alt="Digital Agency" />;

        return (
            <header className={`header-area header-style-two header--fixed ${color}`}>
                <div className="header-wrapper">
                    <div className="header-left d-flex align-items-center">
                        <div className="logo">
                            <a href={this.props.homeLink}>
                                {logoUrl}
                            </a>
                        </div>
                        <nav className="mainmenunav d-lg-block ml--50">
                            <Scrollspy className="mainmenu" items={['home', 'video', 'announcement', 'blog', 'about', 'contact']} currentClassName="is-current" offset={-200}>
                                <li><a href="#home">{t("home")}</a></li>
                                <li><a href="#video">{t("video")}</a></li>
                                <li><a href="#announcement">{t("announcement")}</a></li>
                                <li><a href="#blog">{t("blog")}</a></li>
                                <li><a href="#about">{t("about")}</a></li>
                                <li><a href="#contact">{t("contact")}</a></li>
                            </Scrollspy>
                        </nav>
                    </div>
                    <div className="header-right">
                        {
                            (localStorage.getItem("token"))?
                                <Button
                                    style={{marginRight:'30px',backgroundColor:'#f9004d',borderRadius:'10px',borderColor:'#f9004d'}}
                                    onClick={()=>{
                                        localStorage.removeItem("token");
                                        window.location.reload()
                                    }}
                                >
                                    Çıkış Yap
                                </Button>
                                :
                                <a href="/login">
                                    <Button
                                        style={{marginRight:'30px',backgroundColor:'#f9004d',borderRadius:'10px',borderColor:'#f9004d'}}
                                    >
                                        Giriş Yap
                                    </Button>
                                </a>
                        }
                        <div className="social-share-inner">
                            <ul className="social-share social-style--2 color-black d-flex justify-content-start liststyle">
                                {SocialShare.map((val, i) => (
                                    <li key={i}><a href={`${val.link}`}>{val.Social}</a></li>
                                ))}
                            </ul>
                        </div>
                        {/* Start Humberger Menu  */}
                        <div className="humberger-menu d-block d-lg-none pl--20">
                            <span onClick={this.menuTrigger} className="menutrigger text-white"><FiMenu /></span>
                        </div>
                        {/* End Humberger Menu  */}
                        <div className="close-menu d-block d-lg-none">
                            <span onClick={this.CLoseMenuTrigger} className="closeTrigger"><FiX /></span>
                        </div>
                    </div>
                </div>
            </header>
        )
    }
}
export default HeaderThree;
