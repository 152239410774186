import React, { Component } from "react";
import ModalVideo from 'react-modal-video';
import { FiChevronUp } from "react-icons/fi";
import { FaAngleRight, FaAngleLeft } from "react-icons/fa";
import ScrollToTop from 'react-scroll-up';
//components
import PageHelmet from "../component/common/Helmet";
import Footer from "../component/footer/Footer";
import {t} from "../locales/i18n"
//elements
import Breadcrumb from "../elements/common/Breadcrumb";
import { Link } from "react-router-dom";
import {post} from "../networking/Server";
class VideoPopup extends Component {
    constructor() {
        super()
        this.state = {
            isOpen: false,
            videos: [],
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal() {
        this.setState({ isOpen: true })
    }
    componentDidMount () {
        this.firstfunction()
    }
    firstfunction = async () =>{
        try{
            const result = await post('youtuber/videoviewwebtwo');
            this.setState({
                videos:result.video,
            })
        }catch (e) {
            console.log("Video Veritabanı Hatası(2): " + e)
        }
    }
    render() {
        const {videos} = this.state;
        videos.reverse()
        return (
            <>
                <PageHelmet pageTitle='Youtuber | Videos' />
                {/* Start Breadcrump Area */}
                <Breadcrumb title={t('videos')} />
                {/* End Breadcrump Area */}

                {/* Start Page Wrapper  */}
                <main className="page-wrapper">


                    {/* Start Video Area  */}
                    {
                        videos[0]?
                            <div className="rn-section ptb--120 bg_color--1 line-separator">
                                <a href={'https:///www.youtube.com/watch?v='+videos[0].link}>
                                    <div className="container">
                                        <div className="row sercice-details-content align-items-center">
                                            <div className="col-lg-12">
                                                <div className="thumb position-relative video-thumb video-thumb2">
                                                    <div className="w-100"
                                                         style={{backgroundImage: "url(" + videos[0].videoimage + ")"}}
                                                         alt="Service Images"/>
                                                    <ModalVideo channel='youtube' isOpen={this.state.isOpen}
                                                                videoId={videos[0].link}
                                                                onClose={() => this.setState({isOpen: false})}/>
                                                    <button className="video-popup position-top-center"><span className="play-icon"/>
                                                    </button>
                                                </div>
                                                <div className="content mt--10">
                                                    <h4 className="title">
                                                        <span>{videos[0].subject}</span>
                                                        <p className="description c-1 f-1">{videos[0].explanation}</p>
                                                    </h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>:null
                    }
                    {/* End Video Area  */}
                    {/* Start Videos Area  */}
                    <div className="rn-section ptb--120 bg_color--1">
                        <div className="container">
                            <div className="row sercice-details-content">
                                {
                                    videos?videos.map((video, index) => (
                                        <div className="col-lg-4 mt--40" index={index}>
                                            <a href={'https:///www.youtube.com/watch?v='+video.link}>
                                                <div className="thumb position-relative video-thumb">
                                                    <div className="w-100"
                                                         style={{
                                                             backgroundImage: "url(" + video.videoimage + ")"
                                                         }}
                                                         alt="Service Images"/>
                                                    <button
                                                        className="video-popup position-top-center theme-color md-size"><span className="play-icon"/>
                                                    </button>
                                                </div>
                                                <div className="content mt--10">
                                                    <h4 className="title">
                                                        <span>{video.subject}</span>
                                                        <p className="description c-1 f-1">{video.explanation}</p>
                                                    </h4>
                                                </div>
                                            </a>
                                        </div>
                                    )):null
                                }
                            </div>
                        </div>
                    </div>
                    {/* End Videos Area  */}

                </main>
                {/* End Page Wrapper  */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                {/* Start Footer Area  */}
                <Footer />
                {/* End Footer Area  */}
            </>
        )
    }
}

export default VideoPopup;
