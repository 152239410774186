import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import { Link } from 'react-router-dom';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Footer from "../component/footer/Footer";
import {getQueryVariable} from "../function/getQueryVariable";
import {post} from "../networking/Server";
import {t} from "../locales/i18n"
import { PayPalButton } from "react-paypal-button-v2";
import {Button,Modal,Carousel} from "react-bootstrap";
//images
import Payatthedoor from '../../public/assets/images/payatthedoor.png'
import PayPal from '../../public/assets/images/paypal.png'
import Credit from '../../public/assets/images/credit.png'

const spinner = {
    width: "150px",
    height: "50px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "6px",
    backgroundColor: "#f5f5f5",
    border: "1px solid #ececec",
    overflow: "hidden",
}
const spinnerItem = {
    width: "50px",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontweight: "500",
    fontSize: "16px",
}
const spinnerBtn = {
    cursor: "pointer",
    backgroundColor: "#e9e9e9",
}


let control = false;
let products_id;
const user_id =localStorage.getItem("user_id");
class ProductDetail extends Component {
    constructor(props) {
        super(props)
        this.state = {
            inputData:{
                pay_namesurname:"",
                pay_identity:"",
                pay_postcode:"",
                pay_adress:"",
                pay_phone:"",
                pay_email:"",

                paypal_namesurname:"",
                paypal_identity:"",
                paypal_postcode:"",
                paypal_adress:"",
                paypal_phone:"",
                paypal_email:""
            },

            paypalValues:{
                orderId: '',
                approvalUrl: '',
                returnUrl: '',
                cancelUrl: '',
            },
            paypal_data_control:false,
            pay_data_control:false,
            succes:false,
            button:false,
            realprice:0,
            copyprice:0,
            isOpen: false,
            products:[],
            total:1,
            price:'',
            tab:"",
            knowledge:''
        }
        this.openModal = this.openModal.bind(this)
        products_id = getQueryVariable(this.props.location.search, 'products_id');
    }
    sour(control,price,discount){

        price = price - (price * (discount / 100));

        if(control===1){
            if(this.state.total===1){
                return null
            }
            else{
                this.setState({
                    total:this.state.total-1
                })
            }
        }
        else{
            this.setState({
                total:this.state.total+1
            })
        }
        setTimeout(()=>{
            this.setState({
                realprice:this.state.total*price,
                copyprice:this.state.price*this.state.total
            })
        },300)
    }



    onChange = (e) => {
        const {inputData} = this.state;
        inputData[e.target.name] = e.target.value;
        this.setState({
            inputData
        })
    }

    paypal_buy = async (namesurname, identity, postcode, adress, phone, email) => {
        const paymentmethod = 2;
        const { inputData ,total}=this.state;
        let emailcontrol = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if(namesurname==='' || identity==='' || postcode===null || adress ==='' || phone ==='' || email ===''){
            this.setState({
                paypal_data_control:true,
            })
            return null;
        }
        else if (emailcontrol.test(email) === false) {
            this.setState({
                paypal_data_control:true,
            })
            return null;
        }
        else if(phone.length>=12){
            this.setState({
                paypal_data_control:true,
            })
            return null;
        }
        else if(adress.length<=20){
            this.setState({
                paypal_data_control:true,
            })
            return null;
        }
        else{
            this.setState({
                paypal_data_control:false,
            })
            try{
                await post('api/payatthedoor/payatthedoorset',
                    {
                        namesurname:inputData.paypal_namesurname,
                        identity:inputData.paypal_identity,
                        postcode:inputData.paypal_postcode,
                        adress:inputData.paypal_adress,
                        phone:inputData.paypal_phone,
                        user_id,
                        email:inputData.paypal_email,
                        products_id,
                        paymentmethod,
                        total:total
                    }).then((res)=>{
                    if(res.result){
                        this.setState({succes:true})
                        console.warn("başarılı")
                    }
                    else{
                        console.warn("başarısız")
                    }
                })
            }catch (e) {
                console.log("Buy database error.")
            }
        }
    }


    pay_at_the_door = async (namesurname, identity, postcode, adress, phone, email) => {
        let emailcontrol = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if(namesurname==='' || identity==='' || postcode===null || adress ==='' || phone ==='' || email ===''){
            this.setState({
                pay_data_control:true,
            })
            return null;
        }
        else if (emailcontrol.test(email) === false) {
            this.setState({
                pay_data_control:true,
            })
            return null;
        }
        else if(phone.length>=12){
            this.setState({
                pay_data_control:true,
            })
            return null;
        }
        else if(adress.length<=20){
            this.setState({
                pay_data_control:true,
            })
            return null;
        }
        else{
            this.setState({
                pay_data_control:false,
            })
            const {total} = this.state;
            const paymentmethod = 1;
            try{
                await post('api/payatthedoor/payatthedoorset',{namesurname,identity,postcode,adress,phone,user_id,email,products_id,paymentmethod,total}).then((res)=>{
                    if(res.result){
                        this.setState({succes:true})
                        console.warn("başarılı!")
                    }
                })
            }catch (e) {
                console.log("Buy database error.")
            }
        }
    }





    onTab(tab) {
        this.setState({
            tab: tab
        })
    }


    hasdiscount(price,discount){
        return price - (price * (discount / 100));
    }
    openModal() {
        this.setState({ isOpen: true })
    }
    componentDidMount () {
        this.firstfunction()
    }
    firstfunction = async () =>{
        try{
            const products = await post('youtuber/productsdetail', {products_id});
            this.setState({
                products:products.products,
                price:products.products.price,
                copyprice:products.products.price
            })
                products.products.discount ?
                    this.setState({
                        realprice:this.hasdiscount(products.products.price,products.products.discount)
                    })
                    :
                    this.setState({
                        realprice:products.products.price
                    })

        }catch (e) {
            console.log("Veritabanı Hatası(2): " + e)
        }
    }


    control_func = async (namesurname, identity, postcode, adress, phone, email) => {

    }

    componentDidUpdate  (prevProps, prevState, snapshot) {
        //namesurname, identity, postcode, adress, phone, email

        let emailcontrol = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if(prevState.inputData.paypal_namesurname==='' || prevState.inputData.paypal_identity==='' || prevState.inputData.paypal_postcode===null || prevState.inputData.paypal_adress ==='' || prevState.inputData.paypal_phone ==='' || prevState.inputData.paypal_email ===''){
            control = false
            return null;
        }
        else if (emailcontrol.test(prevState.inputData.paypal_email) === false) {
            control = false
            return null;
        }
        else if(prevState.inputData.paypal_phone.length>=12){
            control = false
            return null;
        }
        else if(prevState.inputData.paypal_adress.length<=20){
            control = false
            return null;
        }else{
            control = true
        }
    }



    render() {
        const {products,inputData,paypalValues} = this.state;
        return (
            <React.Fragment>
                <PageHelmet pageTitle='Blog Details' />
                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area  pb--190 bg_image"
                     style={{
                         backgroundImage: "url(" + products.imageproducts + ")"
                     }}
                     data-black-overlay="7">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="blog-single-page-title text-center pt--50">
                                    <h2 className="title theme-gradient">{products.titleproducts}</h2>
                                    <div className="breadcrumb-inner">
                                        <ul className="page-list">
                                            <li className="breadcrumb-item"><Link to={`${process.env.PUBLIC_URL}`}>{t("home")}</Link></li>
                                            <li className="breadcrumb-item"><Link to={`${process.env.PUBLIC_URL}` + "/products"}>{t("my_products")}</Link></li>
                                            <li className="breadcrumb-item active">{products.titleproducts}</li>
                                            {
                                                (localStorage.getItem("token"))?
                                                    null
                                                    :
                                                    <h3 style={{color:'red'}}>{t('login_to_buy_the_product')}</h3>
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Blog Details */}
                {
                    this.state.succes?
                        <Modal.Dialog style={{borderRadius:'30px'}}>
                            <Modal.Body>
                                <h3>{t("bought")}</h3>
                                <p>
                                    {t("long_text")}
                                    {
                                        this.state.knowledge===""?null:
                                            <div>
                                            {" "+this.state.knowledge}
                                            </div>
                                    }
                                </p>
                            </Modal.Body>
                            <Modal.Footer>
                                <a href="/products">
                                    <Button className="rn-button-style--2 btn-solid"><span>{t("ok")}</span></Button>
                                </a>
                            </Modal.Footer>
                        </Modal.Dialog>

                        :
                        <div className="rn-blog-details pt--50 pb--70 bg_color--1">
                            <div className="container">
                                {/*Start*/}
                                <h3>{products.titleproducts}</h3>
                                <Carousel
                                    pause={false}
                                >
                                    <Carousel.Item>
                                        <div className="thumbnail blog-thumb" style={{alignItems:'center',display:'flex',justifyContent:'center'}}>
                                            <div
                                                 style={{
                                                     backgroundImage: "url(" + products.imageproducts + ")",
                                                     width:500,
                                                     height:500,
                                                     alignSelf:'flex'
                                                 }}
                                                 alt="Blog Images" />
                                        </div>
                                        <Carousel.Caption>
                                            <h3>{products.titleproducts}</h3>
                                        </Carousel.Caption>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <div className="thumbnail blog-thumb">
                                            <div className="w-100"
                                                 style={{
                                                     backgroundImage: "url(" + products.imageproductstwo + ")"
                                                 }}
                                                 alt="Blog Images" />

                                        </div>
                                        <Carousel.Caption>
                                            <h3>{products.titleproducts}</h3>
                                        </Carousel.Caption>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <div className="thumbnail blog-thumb" style={{alignItems:'center',display:'flex',justifyContent:'center'}}>
                                            <div
                                                style={{
                                                    backgroundImage: "url(" + products.imageproductsthree + ")",
                                                    width:500,
                                                    height:500,
                                                    alignSelf:'flex'
                                                }}
                                                alt="Blog Images" />
                                        </div>
                                        <Carousel.Caption>
                                            <h3>{products.titleproducts}</h3>
                                        </Carousel.Caption>
                                    </Carousel.Item>
                                </Carousel>

                                <div style={{marginTop:'100px'}} className="row">
                                    {/* Start Blog Area  */}
                                    <div className="col-lg-4 col-md-6 col-12">
                                        <div className="blog blog-style--1 ">
                                            <div className="thumbnail blog-thumb">
                                                <div className="w-100"
                                                     style={{
                                                         backgroundImage: "url(" + products.imageproducts + ")"
                                                     }}
                                                     alt="Blog Images" />

                                            </div>
                                        </div>
                                    </div>
                                    {/* End Blog Area  */}
                                    {/* Start Blog Area  */}
                                    <div className="col-lg-4 col-md-6 col-12">
                                        <div className="blog blog-style--1 ">
                                            <div className="thumbnail blog-thumb">
                                                <div className="w-100"
                                                     style={{
                                                         backgroundImage: "url(" + products.imageproductstwo + ")"
                                                     }}
                                                     alt="Blog Images" />

                                            </div>
                                        </div>
                                    </div>
                                    {/* End Blog Area  */}
                                    {/* Start Blog Area  */}
                                    <div className="col-lg-4 col-md-6 col-12">
                                        <div className="blog blog-style--1 ">
                                            <div className="thumbnail blog-thumb">
                                                <div className="w-100"
                                                     style={{
                                                         backgroundImage: "url(" + products.imageproductsthree + ")"
                                                     }}
                                                     alt="Blog Images" />

                                            </div>
                                        </div>
                                    </div>
                                    {/* End Blog Area  */}
                                </div>
                                {/*End*/}
                                {/*Start*/}
                                <div style={{display:"flex"}}>
                                    {
                                        products.discount?
                                            <div className="mb--20" style={{ textAlign: "center", width: "100%", display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                                                <h1 className="mb--0" style={{ textAlign: "center" }}>{this.state.realprice}TL</h1>
                                                <h3 className="ml--10 mb--0" style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "flex-start" }}>
                                                    <span className="100TL" style={{ color: "#f81f01" }}>{products.discount}%</span>
                                                    <span style={{ textDecoration: "line-through", color: "#777777" }}> {this.state.copyprice}TL</span>
                                                </h3>
                                            </div>
                                            :
                                            <div className="mb--20" style={{ textAlign: "center", width: "100%", display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                                                <h1 className="mb--0" style={{ textAlign: "center" }}>{this.state.realprice}TL</h1>
                                            </div>
                                    }
                                    <div>
                                        {
                                            (localStorage.getItem("token"))?
                                                <div style={spinner}>
                                                    <div
                                                        onClick={()=>{
                                                            this.sour(1,products.price,products.discount)
                                                        }}
                                                        style={{ ...spinnerItem, ...spinnerBtn }}>-</div>
                                                    <div style={spinnerItem}>{this.state.total}</div>
                                                    <div
                                                        onClick={()=>{
                                                            this.sour(2,products.price,products.discount)
                                                        }}
                                                        style={{ ...spinnerItem, ...spinnerBtn }}>+</div>
                                                </div>
                                                :
                                                null
                                        }

                                    </div>
                                </div>
                                {/*End*/}
                                {
                                    (localStorage.getItem("token"))?
                                        <div className="product-tab">
                                            <div className="product-tab-title">
                                                <div className="product-tab-pic" onClick={() => this.onTab("paypal")}>
                                                    <img src={PayPal} />
                                                    <div style={{marginTop:'20px',fontWeight:'bold'}}>
                                                        Paypal
                                                    </div>
                                                </div>
                                                <div className="product-tab-pic" onClick={() => this.onTab("payatthedoor")}>
                                                    <img src={Payatthedoor} />
                                                    <div style={{marginTop:'20px',fontWeight:'bold'}}>
                                                        {t('pay_at_the_door')}
                                                    </div>
                                                </div>
                                                <div className="product-tab-pic" onClick={() => this.onTab("credit")}>
                                                    <img src={Credit} />
                                                    <div style={{marginTop:'20px',fontWeight:'bold'}}>
                                                        {t('credit_card')}
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{marginTop:'100px'}} className="product-tab-body">
                                                {
                                                    (this.state.tab === "paypal") ?
                                                        <div className="product-tab-section">
                                                            <h3 className="col-md-12">PayPal</h3>
                                                            <div className="form-group col-md-6">
                                                                <label className="col-md-12">{t('product_nameandsurname')}</label>
                                                                <input
                                                                    name={"paypal_namesurname"}
                                                                    value={inputData.paypal_namesurname}
                                                                    onChange={this.onChange}
                                                                    className="form-control col-md-12 m--0"
                                                                />
                                                            </div>
                                                            <div className="form-group col-md-6">
                                                                <label className="col-md-12">E-mail</label>
                                                                <input
                                                                    name={"paypal_email"}
                                                                    value={inputData.paypal_email}
                                                                    onChange={this.onChange}
                                                                    className="form-control col-md-12 m--0" />
                                                            </div>
                                                            <div className="form-group col-md-4">
                                                                <label className="col-md-12">{t('product_identity')}</label>
                                                                <input
                                                                    name={"paypal_identity"}
                                                                    value={inputData.paypal_identity}
                                                                    onChange={this.onChange}
                                                                    className="form-control col-md-12 m--0" />
                                                            </div>
                                                            <div className="form-group col-md-4">
                                                                <label className="col-md-12">{t('product_postcode')}</label>
                                                                <input
                                                                    name={"paypal_postcode"}
                                                                    value={inputData.paypal_postcode}
                                                                    onChange={this.onChange}
                                                                    className="form-control col-md-12 m--0" />
                                                            </div>
                                                            <div className="form-group col-md-4">
                                                                <label className="col-md-12">{t('product_phone')}</label>
                                                                <input
                                                                    name={"paypal_phone"}
                                                                    value={inputData.paypal_phone}
                                                                    onChange={this.onChange}

                                                                    className="form-control col-md-12 m--0" />
                                                            </div>
                                                            <div className="form-group col-md-12">
                                                                <label className="col-md-12">{t('product_address')}</label>
                                                                <textarea
                                                                    name={"paypal_adress"}
                                                                    value={inputData.paypal_adress}
                                                                    onChange={this.onChange}
                                                                    style={{ minHeight: "100px" }} className="form-control col-md-12 m--0" >
                                                        </textarea>
                                                            </div>
                                                            {
                                                                control?
                                                                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                                                        <div className="view-more-btn mt--0 ml--20 text-center">
                                                                            <Button
                                                                                onClick={()=>{
                                                                                    alert("Bu bir demodur.")
                                                                                }}
                                                                                className="rn-button-style--2 btn-solid"><span>{t("buy")}</span></Button>
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    <div style={{marginLeft:'20px',color:'red'}}>
                                                                            {t('control')}
                                                                    </div>
                                                            }
                                                            {
                                                                this.state.paypal_data_control?
                                                                    <span style={{color:'red',marginTop:'13px',marginLeft:'20px'}}>
                                                                {t("please_control")}
                                                            </span>:null

                                                            }
                                                        </div>
                                                        : null
                                                }
                                                {
                                                    //namesurname, identity, postcode, adress, phone, email
                                                    (this.state.tab === "payatthedoor") ?
                                                        <div className="product-tab-section">
                                                            <h3 className="col-md-12">Kapıda Ödeme</h3>
                                                            <div className="form-group col-md-6">
                                                                <label className="col-md-12">{t("product_nameandsurname")}</label>
                                                                <input
                                                                    name={"pay_namesurname"}
                                                                    value={inputData.pay_namesurname}
                                                                    onChange={this.onChange}
                                                                    className="form-control col-md-12 m--0"
                                                                />
                                                            </div>
                                                            <div className="form-group col-md-6">
                                                                <label className="col-md-12">E-mail</label>
                                                                <input
                                                                    name={"pay_email"}
                                                                    value={inputData.pay_email}
                                                                    onChange={this.onChange}
                                                                    className="form-control col-md-12 m--0" />
                                                            </div>
                                                            <div className="form-group col-md-4">
                                                                <label className="col-md-12">{t("product_identity")}</label>
                                                                <input
                                                                    name={"pay_identity"}
                                                                    value={inputData.pay_identity}
                                                                    onChange={this.onChange}
                                                                    className="form-control col-md-12 m--0" />
                                                            </div>
                                                            <div className="form-group col-md-4">
                                                                <label className="col-md-12">{t("product_postcode")}</label>
                                                                <input
                                                                    name={"pay_postcode"}
                                                                    value={inputData.pay_postcode}
                                                                    onChange={this.onChange}
                                                                    className="form-control col-md-12 m--0" />
                                                            </div>
                                                            <div className="form-group col-md-4">
                                                                <label className="col-md-12">{t("product_phone")}</label>
                                                                <input
                                                                    name={"pay_phone"}
                                                                    value={inputData.pay_phone}
                                                                    onChange={this.onChange}

                                                                    className="form-control col-md-12 m--0" />
                                                            </div>
                                                            <div className="form-group col-md-12">
                                                                <label className="col-md-12">{t("product_address")}</label>
                                                                <textarea
                                                                    name={"pay_adress"}
                                                                    value={inputData.pay_adress}
                                                                    onChange={this.onChange}

                                                                    style={{ minHeight: "100px" }} className="form-control col-md-12 m--0" >
                                                        </textarea>
                                                            </div>
                                                            <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                                                <div className="view-more-btn mt--0 ml--20 text-center">
                                                                    <Button
                                                                        onClick={()=>{
                                                                            alert("Bu bir demodur.")
                                                                        }}
                                                                        className="rn-button-style--2 btn-solid"><span>{t("buy")}</span></Button>
                                                                </div>
                                                            </div>
                                                            {
                                                                this.state.pay_data_control?
                                                                    <span style={{color:'red',marginTop:'13px',marginLeft:'20px'}}>
                                                                {t("please_control")}
                                                            </span>:null

                                                            }
                                                        </div>
                                                        : null
                                                }
                                                {
                                                    (this.state.tab === "credit") ?
                                                        <div className="product-tab-section">
                                                            <h3 className="col-md-12">Kredi Kartı İle Ödeme</h3>
                                                            <div className="form-group col-md-6">
                                                                <label className="col-md-12">{t("product_nameandsurname")}</label>
                                                                <input
                                                                    name={"pay_namesurname"}
                                                                    value={inputData.pay_namesurname}
                                                                    onChange={this.onChange}
                                                                    className="form-control col-md-12 m--0"
                                                                />
                                                            </div>
                                                            <div className="form-group col-md-6">
                                                                <label className="col-md-12">E-mail</label>
                                                                <input
                                                                    name={"pay_email"}
                                                                    value={inputData.pay_email}
                                                                    onChange={this.onChange}
                                                                    className="form-control col-md-12 m--0" />
                                                            </div>
                                                            <div className="form-group col-md-4">
                                                                <label className="col-md-12">{t("product_identity")}</label>
                                                                <input
                                                                    name={"pay_identity"}
                                                                    value={inputData.pay_identity}
                                                                    onChange={this.onChange}
                                                                    className="form-control col-md-12 m--0" />
                                                            </div>
                                                            <div className="form-group col-md-4">
                                                                <label className="col-md-12">{t("product_postcode")}</label>
                                                                <input
                                                                    name={"pay_postcode"}
                                                                    value={inputData.pay_postcode}
                                                                    onChange={this.onChange}
                                                                    className="form-control col-md-12 m--0" />
                                                            </div>
                                                            <div className="form-group col-md-4">
                                                                <label className="col-md-12">{t("product_phone")}</label>
                                                                <input
                                                                    name={"pay_phone"}
                                                                    value={inputData.pay_phone}
                                                                    onChange={this.onChange}

                                                                    className="form-control col-md-12 m--0" />
                                                            </div>
                                                            <div className="form-group col-md-12">
                                                                <label className="col-md-12">{t("product_address")}</label>
                                                                <textarea
                                                                    name={"pay_adress"}
                                                                    value={inputData.pay_adress}
                                                                    onChange={this.onChange}
                                                                    style={{ minHeight: "100px" }} className="form-control col-md-12 m--0" >
                                                        </textarea>
                                                            </div>
                                                            <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                                                <div className="view-more-btn mt--0 ml--20 text-center">
                                                                    <Button onClick={()=>{
                                                                        alert("Bu bir demodur.")
                                                                    }} className="rn-button-style--2 btn-solid"><span>{t("buy")}</span></Button>
                                                                </div>
                                                            </div>
                                                            {
                                                                this.state.pay_data_control?
                                                                    <span style={{color:'red',marginTop:'13px',marginLeft:'20px'}}>
                                                                {t("please_control")}
                                                            </span>:null

                                                            }
                                                        </div>
                                                        : null
                                                }
                                            </div>
                                            {/*Start*/}
                                            {
                                                /*
                                                <div style={{display:'flex'}}>
                                                <input type="checkbox" style={{ width: "25px" }} />
                                                <span className="mt--10">Ön Bilgilendirme Formu</span>
                                                <button className="btn btn-primary btn-sm mt--10 ml--10"><span>Oku</span></button>
                                                <label className="mb--10" style={{  display: 'flex' }}>
                                                </label>
                                            </div>

                                            <div style={{display:'flex'}}>
                                                <input type="checkbox" style={{ width: "25px" }} />
                                                <span className="mt--10">Ön Bilgilendirme Formu</span>
                                                <button className="btn btn-primary btn-sm mt--10 ml--10"><span>Oku</span></button>
                                                <label className="mb--10" style={{  display: 'flex' }}>
                                                </label>
                                            </div>
                                                * */
                                            }
                                            {/*End*/}
                                            {/*Start*/}
                                            {/*End*/}
                                        </div> :null
                                }
                                {/*Start*/}
                                <div className="row" style={{marginTop:'50px',marginLeft:'10px'}}>
                                    <p>{products.explainproducts}</p>
                                </div>
                                {/*End*/}
                            </div>
                        </div>
                }
                {/* End Blog Details */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />

            </React.Fragment>
        )
    }
}
export default ProductDetail;
