let token="";


export async function post(adres,params=null,func=()=>{}) {

    if(params && !Array.isArray(params)){
        params=JSON.stringify(params);
    }

    return fetch(getURL()+adres, {
        method: 'POST',
        mode: 'cors',
        redirect: 'follow',
        headers: new Headers({
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'x-access-token': "Bearer " +(localStorage.getItem("token") ||"")
        }),
        body: params,
    }).then((response) => response.json()).then((data) => {
        console.warn(data) //Veriler burada bağlanılıyor. Token doğruluğu olmaz ise "Token bulunamadı" çıktısı verir.
        func();
        return data;
    }).catch((err)=>{
        func();
        return {result:false,error:"No_Connect (Server.js)"};
    });


}

export function getURL(){
    return "https://cyberistanbul.com:5019/";
}
export function getImageURL(){
    return "https://cyberistanbul.com/letvise/";
}

export function setToken(_token) {
    token=_token;
}


