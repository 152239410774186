import React, { Component } from 'react'
import {t} from "../locales/i18n"
import {post} from "../networking/Server";
import {goPage} from "../function/goPage";
import {Button} from "react-bootstrap";
export default class Login extends Component {
    constructor() {
        super()
        this.state = {
            inputData:{
                username:'',
                password:'',
                password_again:'',
                nameandsurname:'',
                email:'',
                phone:'',
            },
            active: false,
            not_found:false,
            control:false,
        }
    }
    onLoginPanel() {
        this.setState((state) => ({
            active: !state.active
        }))
    }
    Control(){
        let sayi;
        const { inputData } = this.state;
        let emailcontrol = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if(inputData.nameandsurname==='' || inputData.username==='' || inputData.password==='' || inputData.email==='' || inputData.phone === ''){
            this.setState({
                control:true
            })
        }else if(inputData.nameandsurname.length > 25){
            this.setState({
                control:true
            })
        }else if(inputData.password.length < 8){
            this.setState({
                control:true
            })
        }
        else if(inputData.password !== inputData.password_again){
            this.setState({
                control:true
            })
        }else if(inputData.phone.length >13){
            this.setState({
                control:true
            })
        }
        else if (emailcontrol.test(inputData.email) === false) {
            this.setState({
                control:true
            })
        }
        else{
            for (sayi = 0; sayi <= inputData.username.length; sayi++) {
                if (inputData.username.charAt(sayi) === ' ') {
                    this.setState({
                        control:true
                    })
                    return null;
                }
            }
            this.onRegister(inputData.nameandsurname,inputData.username,inputData.password,inputData.email,inputData.phone)
        }
    }
    onRegister = async  (nameandsurname,username,password,email,phone) =>{
        this.setState({
            loading:true
        })
        await post("register",{nameandsurname, username, password, email, phone}).then((res)=>{
            try {
                if(res.result){
                    window.location.reload()
                }else{
                    if(res.message === 'Bu kullanıcı ismi kullanımda!'){
                        console.warn("bu isim kullanımda")
                    }else{
                        console.warn("bir problem var")
                    }
                }
            }catch (e) {
                console.log("Error(Register-app)" + e);
            }
        });

    }
    login = async (username,password) => {
        if(!username || !password){
            return;
        }
        await post("login",{username, password}).then((res)=>{
            console.warn(res)
            try {
                if(res.result){
                    post("api/auth/get_me").then((res)=>{
                        try {
                            if(res.result){
                                localStorage.setItem("user_id", res.user.user_id.toString())
                            }else{
                                if(res.error) {
                                    this.setState({
                                        not_found:true
                                    })
                                }
                            }
                        }catch (e) {

                        }
                    });
                    localStorage.setItem("token", res.token.toString())
                    goPage(this,"/products")
                }
                else{
                    this.setState({
                        not_found:true
                    })
                }
            }catch (e) {
                console.warn("dsfaf")
            }
        });
    }
    onChange = (e) => {
        const {inputData} = this.state;
        inputData[e.target.name] = e.target.value;
        this.setState({
            inputData
        })
    }
    render() {
        var { active = false , inputData } = this.state
        console.warn(inputData)
        return (
            <>
                <div className="login">
                    <div class={("container") + ((active) ? " right-panel-active" : "")} id="container">
                        <div class="form-container sign-up-container">
                            <form action="#">
                                <h1>{t("register")}</h1>
                                <span>{t("enteryourinformation")}</span>
                                <input
                                    name={"nameandsurname"}
                                    value={inputData.nameandsurname}
                                    onChange={this.onChange}
                                    type="text"
                                    placeholder={t("nameandsurname")}
                                />
                                <input
                                    name={"username"}
                                    value={inputData.username}
                                    onChange={this.onChange}
                                    type="text"
                                    placeholder={t("username")}
                                />
                                <input
                                    name={"email"}
                                    value={inputData.email}
                                    onChange={this.onChange}
                                    type="email"
                                    placeholder="Email"
                                />
                                <input
                                    name={"password"}
                                    value={inputData.password}
                                    onChange={this.onChange}
                                    type="password"
                                    placeholder={t("password")}
                                />
                                <input
                                    name={"password_again"}
                                    value={inputData.password_again}
                                    onChange={this.onChange}
                                    type="password"
                                    placeholder={t("password")}
                                />
                                <input
                                    name={"phone"}
                                    value={inputData.phone}
                                    onChange={this.onChange}
                                    type="text"
                                    placeholder={t("phone")}
                                />
                                <Button
                                    style={{backgroundColor:'#ff4b2b',borderColor:'#ff4b2b'}}
                                    onClick={()=>{this.Control()}}
                                    className="mt--10">{t("register")}
                                </Button>
                                {
                                    this.state.control?
                                        <div style={{margin:'20px',color:'red'}}>
                                            Girdiğiniz verilerde hata var.
                                        </div>:null
                                }
                            </form>
                        </div>
                        <div class="form-container sign-in-container">
                            <form action="#">
                                <h1>{t("login")}</h1>
                                <span>{t("enteryourinformation")}</span>
                                <input name={"username"}
                                       value={inputData.username}
                                       onChange={this.onChange}
                                       placeholder={t("username")}
                                />
                                <input
                                    name={"password"}
                                    value={inputData.password}
                                    onChange={this.onChange}
                                    type="password"
                                    placeholder={t("password")}
                                />
                                <Button
                                    style={{backgroundColor:'#ff4b2b',borderColor:'#ff4b2b'}}
                                    onClick={()=>{this.login(inputData.username,inputData.password)}}>{t("login")}</Button>
                                {
                                    this.state.not_found?
                                        <div style={{margin:'20px',color:'red'}}>
                                            Kullacı bulunamadı!
                                        </div>:null
                                }
                            </form>
                        </div>
                        <div class="overlay-container">
                            <div class="overlay">
                                <div class="overlay-panel overlay-left">
                                    <h1>{t("firsttext")}</h1>
                                    <p>{t("secondtext")}</p>
                                    <button class="ghost" id="signIn" onClick={() => this.onLoginPanel()}>{t("login")}</button>
                                </div>
                                <div class="overlay-panel overlay-right">
                                    <h1>{t("firsttext")}</h1>
                                    <p>{t("thirdtext")}</p>
                                    <button class="ghost" id="signUp" onClick={() => this.onLoginPanel()}>{t("register")}</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <footer>
                        <p>
                            <i class="fa fa-heart"></i>
                            from
                            <a target="_blank" href="https://www.cyberistanbul.com"> @Cyber Istanbul</a>
                </p>
                    </footer>
                </div>
            </>
        )
    }
}
