import React, { Component } from "react";
import ModalVideo from 'react-modal-video';
import { FiChevronUp } from "react-icons/fi";
import { FaAngleRight, FaAngleLeft } from "react-icons/fa";
import ScrollToTop from 'react-scroll-up';
//components
import PageHelmet from "../component/common/Helmet";
import Footer from "../component/footer/Footer";
//elements
import {t} from "../locales/i18n"
import Breadcrumb from "../elements/common/Breadcrumb";
import { Link } from "react-router-dom";
import {post} from "../networking/Server";
import {goPage} from "../function/goPage";


class VideoPopup extends Component {
    constructor() {
        super()
        this.state = {
            isOpen: false,
            blogs:[],
            abouts:[],
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal() {
        this.setState({ isOpen: true })
    }
    componentDidMount () {
        this.firstfunction()
    }
    firstfunction = async () =>{
        try{
            const blog = await post('youtuber/webtwo');
            const about = await post('youtuber/getyoutuber');
            this.setState({
                blogs:blog.blog,
                abouts:about.youtuber
            })
        }catch (e) {
            console.log("Blog Veritabanı Hatası(2): " + e)
        }
    }
    render() {
        const {blogs ,abouts} = this.state;
        blogs.reverse()
        return (
            <>
                <PageHelmet pageTitle='Youtuber | Blogs' />
                {/* Start Breadcrump Area */}
                <Breadcrumb title={t("my_blogs")} />
                {/* End Breadcrump Area */}

                {/* Start Page Wrapper  */}
                <main className="page-wrapper">

                    {/* Start Blogs Area  */}
                    <div className="rn-section ptb--120 bg_color--1">
                        <div className="container">
                            <div className="row sercice-details-content align-items-center">
                                {
                                    blogs?abouts[0]?blogs.map((blog, i) => (
                                        <div className="col-lg-4 col-md-6 col-12" key={i}>
                                            <div className="blog blog-style--1 ">
                                                <a>
                                                    <div className="thumbnail blog-thumb">
                                                        {
                                                            blog.imageblog?
                                                                <div className="w-100"
                                                                     style={{
                                                                         backgroundImage: "url(" + blog.imageblog + ")"
                                                                     }}
                                                                     alt="Blog Images"
                                                                />
                                                                :
                                                                <div className="w-100"
                                                                    //require('../images/blog.jpg')
                                                                     style={{
                                                                         backgroundImage: "url(" + `${abouts[0].defaultblog}`+ ")"
                                                                     }}
                                                                     alt="Blog Images"
                                                                />
                                                        }
                                                    </div>
                                                </a>
                                                <div className="content">
                                                    <h4 className="title"><a>{blog.title}</a></h4>
                                                    <div className="blog-btn">
                                                        <a className="rn-btn text-white" onClick={()=>{
                                                            goPage(this,"/blogs/detail",{blog_id:blog.blog_id},false)
                                                        }}>{t("blogs_detail")}</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )):null:null
                                }
                            </div>
                        </div>
                    </div>
                </main>
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                <Footer />
            </>
        )
    }
}

export default VideoPopup;
