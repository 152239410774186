import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ModalVideo from 'react-modal-video';
import { FiClock, FiUser, FiMessageCircle, FiHeart } from "react-icons/fi";
import { Link } from 'react-router-dom';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Footer from "../component/footer/Footer";
import {getQueryVariable} from "../function/getQueryVariable";
import {post} from "../networking/Server";
import parse from 'html-react-parser';
let blog_id;
class BlogDetails extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isOpen: false,
            blog:[]
        }
        this.openModal = this.openModal.bind(this)
        blog_id = getQueryVariable(this.props.location.search, 'blog_id');
    }
    openModal() {
        this.setState({ isOpen: true })
    }
    componentDidMount () {
        this.firstfunction()
    }
    firstfunction = async () =>{
        try{
            const blg = await post('youtuber/webdetail', {blog_id});
            this.setState({
                blog:blg.blog,
            })
        }catch (e) {
            console.log("Veritabanı Hatası(2): " + e)
        }
    }
    render() {
        const {blog} = this.state;
        console.warn(blog)
        return (
            <React.Fragment>
                <PageHelmet pageTitle='Blog Details' />
                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area ptb--120 pb--190 bg_image"
                     style={{
                         backgroundImage: "url(" + blog.imageblog + ")"
                     }}
                     data-black-overlay="7">
                    {
                        blog?
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="blog-single-page-title text-center pt--100">
                                            <h2 className="title theme-gradient">{blog.title}</h2>
                                            <div className="breadcrumb-inner pt--100">
                                                <ul className="page-list">
                                                    <li className="breadcrumb-item"><Link to={`${process.env.PUBLIC_URL}`}>Ana Sayfa</Link></li>
                                                    <li className="breadcrumb-item"><Link to={`${process.env.PUBLIC_URL}` + "/blogs"}>Blog</Link></li>
                                                    <li className="breadcrumb-item active">{blog.title}</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :null
                    }
                </div>
                {/* End Breadcrump Area */}

                {/* Start Announcement Details */}
                <div className="rn-blog-details pt--110 pb--70 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner-wrapper">
                                    <div className="inner">
                                        <p>{parse(`${blog.longtext}`)}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Announcement Details */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />

            </React.Fragment>
        )
    }
}
export default BlogDetails;
