import React,{Component} from 'react';
import {FaTwitter ,FaInstagram ,FaFacebookF , FaLinkedinIn,FaYoutube} from "react-icons/fa";
import {post} from "../../networking/Server";


class FooterTwo extends Component{
    constructor(props) {
        super(props)
        this.state = {
            abouts:[],
            SocialShare : [
                {Social: <FaFacebookF /> , link: ''},
                {Social: <FaYoutube /> , link: ''},
                {Social: <FaInstagram /> , link: ''},
                {Social: <FaTwitter /> , link: ''},
            ]
        }
    }
    componentDidMount () {
        this.firstfunction()
    }
    firstfunction = async () =>{
        try{
            const about = await post('youtuber/getyoutuber');
            this.setState({
                abouts:about.youtuber,
                SocialShare : [
                    {Social: <FaFacebookF /> , link: about.youtuber[0].facebook},
                    {Social: <FaYoutube /> , link: about.youtuber[0].youtube},
                    {Social: <FaInstagram /> , link: about.youtuber[0].instagram},
                    {Social: <FaTwitter /> , link: about.youtuber[0].twitter},
                ]
            })
        }catch (e) {
            console.log("Veritabanı Hatası (web): " + e)
        }
    }
    render() {
        const {abouts,SocialShare}=this.state;
        return (
            <div className="footer-style-2 ptb--30 bg_image bg_image--1" data-black-overlay="6">
                <div className="wrapper plr--50 plr_sm--20">
                    <div className="row align-items-center justify-content-between">
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                            <div className="inner">
                                <div className="logo text-center text-sm-left mb_sm--20">
                                    <a>
                                        {
                                            abouts[0]?<img style={{borderRadius:100,width:50,height:50}} src={`${abouts[0].iconimage}`} alt="Logo images"/>:null
                                        }
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                            <div className="inner text-center">
                                <ul className="social-share rn-lg-size d-flex justify-content-center liststyle">
                                    {
                                        SocialShare.map((val , i) => (
                                            <li key={i}><a href={`${val.link}`}>{val.Social}</a></li>
                                        ))
                                    }
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                            <div className="inner text-lg-right text-center mt_md--20 mt_sm--20">
                                <div className="text">
                                    <p>Copyright © 2020 CyberIstanbul.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default FooterTwo;
