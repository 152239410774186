// React Required
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Switch, Route, Router, HashRouter } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
// Create Import File
import './index.scss';
//routes
import Home from './routes/home'
import Videos from "./routes/videos";
import Announcements from './routes/announcements'
import AnnouncementDetails from './routes/announcement-details'
import Blogs from './routes/blogs'
import BlogDetails from './routes/blog-details'
import Products from "./routes/products";
import productDetail from "./routes/ProductDetail";
import error404 from "./elements/error404";
import Login from "./routes/Login";
import {post} from "./networking/Server";

class Root extends Component {
    constructor() {
        super();
        this.state={
            control:false
        }
    }
    componentDidMount() {
        this.isValidToken()
    }

    async isValidToken(){
        const token = localStorage.getItem("token");
        if(token && token !== "undefined"){
            const result = await post('api/auth/get_me_web',{token});
            if(result.error === 1){
                localStorage.setItem("token","");
                this.setState({
                    control: false
                })
            }else{
                localStorage.setItem("token", result.token);
                this.setState({
                    control: true
                })
            }
        }else{
            localStorage.setItem("token","");
            this.setState({
                control: false
            })
        }
    }
    render() {
        return (
                <BrowserRouter basename={"/"}>
                    <Switch>
                        <Route exact path={`/`} component={Home} />
                        <Route exact path={`/login`} component={Login} />
                        <Route exact path={`/videos`} component={Videos} />
                        <Route exact path={`/announcements`} component={Announcements} />
                        <Route  path={`/announcements/detail`} component={AnnouncementDetails} />
                        <Route exact path={`/products`} component={Products} />
                        <Route  path={`/products/detail`} component={productDetail} />
                        <Route exact  path={`/blogs`} component={Blogs} />
                        <Route  path={`/blogs/detail`} component={BlogDetails} />
                        <Route  path={`/404`} component={error404} />
                        <Route path={"*"} component={error404} />
                    </Switch>
                </BrowserRouter>
        )
    }
}

ReactDOM.render(<Root />, document.getElementById('root'));
serviceWorker.register();
