import React, { Component } from "react";
import ModalVideo from 'react-modal-video';
import { FiChevronUp } from "react-icons/fi";
import { FaAngleRight, FaAngleLeft } from "react-icons/fa";
import ScrollToTop from 'react-scroll-up';
//components
import PageHelmet from "../component/common/Helmet";
import Footer from "../component/footer/Footer";
//elements
import Breadcrumb from "../elements/common/Breadcrumb";
import { Link } from "react-router-dom";
import {post} from "../networking/Server";
import {goPage} from "../function/goPage";


class Products extends Component {
    constructor() {
        super()
        this.state = {
            isOpen: false,
            products:[],
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal() {
        this.setState({ isOpen: true })
    }
    hasdiscount(price,discount){
        return price - (price * (discount / 100));
    }
    componentDidMount () {
        this.firstfunction()
    }
    firstfunction = async () =>{
        try{
            const products = await post('youtuber/productsviewweb');
            this.setState({
                products:products.products.reverse(),
            })
        }catch (e) {
            console.log("Blog Veritabanı Hatası(2): " + e)
        }
    }
    render() {
        const {products} = this.state;
        return (
            <>
                <PageHelmet pageTitle='Youtuber | Products' />
                {/* Start Breadcrump Area */}
                <Breadcrumb title={'Ürünlerim'} />
                {/* End Breadcrump Area */}
                {/* Start Page Wrapper  */}
                <main className="page-wrapper">
                    {/* Start Blogs Area  */}
                    <div className="rn-section ptb--120 bg_color--1">
                        <div className="container">
                            <div className="row sercice-details-content align-items-center">
                                {
                                    products?products.map((products, i) => (
                                        <div className="col-lg-4 col-md-6 col-12 mb-5" key={i}>
                                            <div className="service mb--20 ">
                                                <div className="thumbnail blog-thumb ">
                                                    <div className="w-100 h-75"
                                                         style={{
                                                             backgroundImage: "url(" + products.imageproducts + ")"
                                                         }}
                                                    />
                                                    <div className="mt-3">
                                                        <div style={{display:'flex'}}>
                                                            <div>
                                                                <h4 className="title"><a>{products.titleproducts.substring(0,5)}...</a></h4>
                                                                <div className="blog-btn">
                                                                    <a className="rn-btn text-black-50"  onClick={()=>{
                                                                        goPage(this,"/products/detail",{products_id:products.products_id},false)
                                                                    }}>Ürün Detayı</a>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                {
                                                                    products.discount?
                                                                        <div style={{ display: "flex" }}>
                                                                            <h2 className="mb--0" >{this.hasdiscount(products.price,products.discount)}TL</h2>
                                                                            <h3 className="mb--0" style={{ display: "flex", flexDirection: "column", margin:8  }}>
                                                                                <span className="100TL" style={{ color: "#f81f01" }}>{products.discount}%</span>
                                                                                <span style={{ textDecoration: "line-through", color: "#777777" }}>{products.price}TL</span>
                                                                            </h3>
                                                                        </div>
                                                                        :
                                                                        <div style={{  display: "flex" }}>
                                                                            <h1 className="mb--0" >{products.price}TL</h1>
                                                                        </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )):null
                                }
                            </div>
                        </div>
                    </div>
                </main>
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                <Footer />
            </>
        )
    }
}

export default Products;
